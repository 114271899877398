form.form {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .inputs {
        display: flex;
        gap: 10px;
        .input-half {
            width: 50%;
        }
    }
    input,
    textarea,
    select {
        width: 100%;
        height: 50px;
        background: none;
        border: 0;
        font-size: 16px;
        color: white;
        transition: .3s;
        border: 1px solid #141414;
        border-bottom: 1px solid #8b8b8b;
        padding: 20px;
        &[type=file] {
            padding-top: 18px;
        }
        &[type=file]::file-selector-button {
            display: none;
        }
        &:focus {
            border-color: white;
        }
    }
    textarea {
        height: 300px;
        resize: vertical;
    }
    select {
        padding: {
            top: 0;
            bottom: 0;
            appearance: none;
        }
        option {
            color: black;
            line-height: 30px;
        }
    }
    .images_preview {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 10px;
        .single_file {
            padding: 3px;
            border: 1px solid #eee;
            height: 50px;
            transition: .3s;
            &:hover {
                background: #fff;
                a {
                    color: black;
                }
            }
            @include toCenter();
            img {
                height: 100%;
                width: fit-content; 
            }
            a {
                padding: 0 20px;
                color: white;
            }
        }
    }
    button[type=submit] {
        width: 50%;
        margin: 0 auto;
        background: white;
        height: 60px; 
        font-size: 20px;
        cursor: pointer;
        border: 0;
        transition: .5s ease-out;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(255, 255, 255, 0.458);
            width: 100%;
            height: 100%;
            transition: .3s;
            z-index: -1;
        }
        &:hover {
            background: #f5ad40; 
            &::after {
                top: 10px;
                left: 10px;
            }
        }
    }
}
@media (max-width: 768px) {
    form.form {  
        margin-top: 20px;
        .inputs {
            flex-direction: column;
            .input-half {
                width: 100%;
            }
        }
        input,
        textarea { 
            padding: 15px 10px; 
            height: 40px;
        }
        textarea {
            height: 120px;
        }
    }
}