.homePage {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.hero_section {
    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 50px;
    .title_component {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        font-size: 100px;
        color: white;
        padding-top: 150px;
        margin: 0 auto;
        margin-bottom: 20px;
        text-transform: uppercase;
        .line {
            text-align: center !important;
            height: 1em;
            overflow: hidden;
        }
        .char {
            opacity: 0;
            transform: translateX(.5em);
            transition: 1s;
        }
    }
    p {
        width: 100%;
        text-align: center;
        color: white;
        font-size: 19px;
        line-height: 1.6;
        margin-top: 50px;
    }
    .inpage_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding-bottom: 20px;
        margin-top: 80px;
        border-bottom: 1px solid white; 
        top: 100px;
        a {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            padding-right: 15px;
            transition: .3s;
            border-right: 1px solid #bc8534
            ;
            &:last-child {
                padding-right: 0;
                border-right: 0;
            }
            &:hover {
                color: #BC8534;
            }
        }
    }
}
@media (max-width: 768px) {
    .hero_section {
        padding: 0 20px;
        .title_component { 
            font-size: 40px; 
            padding-top: 0;
        }
        p {
            font-size: 14px;
            margin-top: 20px;
        }
        .inpage_nav {
            flex-wrap: wrap;
            a {
                white-space: nowrap; 
            }
        }
    }
}

.video_banner {
    display: flex; 
    gap: 50px;
    min-height: 70vh;
    position: relative; 
    width: 100%; 
    // opacity: 0;
    transition: 1.5s;
    &.animate_left {
        box-shadow: unset;
        border: {
            top: 1px solid white;
            bottom: 1px solid white
        } 
    }
    .column {
        box-shadow: 0 0 80px 0 rgba($color: #f5ad40, $alpha: .08); 
        width: 100%;
        position: relative;
        height: inherit;
        @include toCenter();
        &::after {
            content: '';
            position: absolute;
            left: 0; 
            top: 0;
            width: 100%;
            height: 100%;
            background: url(/public/images/GoldBanner.png);
            background-size: 100% 100px;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: .8;
        }
        h2 { 
            font-size: 50px; 
            white-space: nowrap;
            z-index: 10;
            // transform: translateY(-.5em);
            width: 70%;
            color: white;
            text-shadow: 1px 1px 2px #030200; 
            text-align: center;
            // opacity: 0;
            z-index: 4;
        }
        video {
            position: absolute;
            right: 0;
            left: 0;
            pointer-events: none;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
@media (max-width:1024px) {
    .video_banner {
        flex-direction: column;
        padding-right: 5%;
        .column {
            min-height: 400px;
            h2 {
                font-size: 30px;
                width: 90%;
            }
            p {
                width: 100%;
                font-size: 15px;
                line-height: 20px;
                &::before {
                    width: 12%;
                }
            }
        }
    }
}

footer {
    display: flex;
    align-items: center;
    margin-top: 100px;
    padding-bottom: 60px;
    padding-left: 20px;
    .left_side {
        width: 40%;
        label {
            color: white;
            font-size: 20px;
        }
        .input_container {
            position: relative;
            width: 300px;
            max-width: 90%;
            margin-top: 20px;
            button {
                position: absolute;
                right: 0;
                color: #8b8b8b;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                background: transparent;
                border: 0;
                cursor: pointer;
                transition: .3s;
                &:hover {
                    color: white;
                    transform: translate(-50%,-50%);
                }
            }
            input {
                width: 100%;
                height: 30px;
                background: none;
                border: 0;
                font-size: 16px;
                color: white;
                transition: .3s;
                border-bottom: 1px solid #8b8b8b;
                &:focus {
                    border-color: white;
                }
            }
        }
        .location {
            margin-top: 60px;
            p {
                color: var(--light-gray);
                font-size: 16px;
                line-height: 20px;
                font-family: Whyte_Extra_Light;
                padding-bottom: 20px;
            }
            a {
                color: white;
            }
        }
    }
    .right_side {
        width: 60%;
        .footer_links {
            display: flex;
            flex-direction: column;
            a {
                color: white;
                font-size: 80px;
                font-family: Whyte_Bold;
                display: flex;
                align-items: center;
                gap: 10px;
                &:hover {
                    .arrow_icon {
                        transform: scale(1);
                        i {
                            transform: translateX(0);
                            &:nth-child(2) {
                                opacity: 0;
                            }
                        }
                    }
                }
                .arrow_icon {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center; 
                    border-radius: 50%;
                    background: #e8e8e8;
                    cursor: pointer;
                    transition: .3s;
                    transform: scale(0);
                    overflow: hidden;
                    i {
                        @include toCenter();
                        min-height: 40px;
                        min-width: 40px;
                        font-size: 20px;
                        color: #343434;
                        transform: translateX(-100%);
                        transition: .5s ease-in-out;
                    }
                }
            }
        }
        .copy_right {
            padding-top: 160px;
            color: var(--light-gray);
        }
    }
}
@media (max-width:1024px) {
    footer {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
        .left_side {
            width: 100%;
        }
        .right_side {
            width: 100%;
            .footer_links {
                a {
                    font-size: 50px;
                }
            }
            .copy_right {
                padding-top: 60px;
                font-size: 12px;
            }
        }
    }
}