.about_page {
    padding-top: 200px;
    @media (max-width:767px) {
        padding-top: 50px;
    }
    * {
        color: white;
    }
    .top_section {
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin-left: 5%;
        .top_text {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-left: 12.7%;
            h1 {
                font-size: 80px;
            }
            p {
                font-size: 22px;
                line-height: 1.4;
                font-family: Whyte_Light;
            }
        }
        .about_three_animations {
            display: grid;
            width: 75%;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px; 
            margin-left: 12.7%;
            .hero_building {
                grid-column: 1 / 3;
            }
            video {
                width: 100%; 
                object-fit: contain;
                pointer-events: none;
            }
        }
    }
    @media (max-width:767px) {
        .top_section {
            gap: 30px;
            .top_text {
                width: 80%;
                h1 {
                    font-size: 30px;
                }
                p {
                    font-size: 18px;
                }
            }
            .about_three_animations {
                width: 80%;
                grid-template-columns: 1fr;
                .hero_building {
                    grid-column: unset;
                }
            }
        }
    }
    .section_2 {
        margin-top: var(--sections-spacing);
        h2 {
            position: relative;
            font-size: 80px;
            color: white;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: calc(100% + 10px);
                height: 3px;
                background: white;
                width: 10%;
            }
        }
        .section_content {
            margin-top: 70px;
            display: flex;
            align-items: flex-start;
            p {
                width: 55%;
                font-size: 25px;
                line-height: 1.4;
                font-family: Whyte_Light;
            }
            img.section_media {
                width: 40%;
                height: 250px;
                object-fit: contain;
            }
        }
    }
    @media (max-width:767px) {
        .section_2 {
            h2 {
                font-size: 40px;
            }
            .section_content {
                flex-direction: column;
                gap: 20px;
                p {
                    width: 100%;
                    font-size: 17px;
                }
                img.section_media {
                    width: 100%;
                }
            }
        }
    }
    .section_3 {
        margin: {
            top: var(--sections-spacing);
            left: 5%;
            right: 5%;
        }
        h2 {
            position: relative;
            font-size: 80px;
            color: white;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: calc(100% + 10px);
                height: 3px;
                background: white;
                width: 10%;
            }
        }
        .section_content {
            display: grid;
            width:100%;
            grid-template-columns: repeat(3 , 1fr);
            grid-gap: 20px;
            padding-top: 100px;
            .text_icon {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .icon {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    border-radius: 50%;
                    object-fit: contain;
                }
                h3 {
                    font-size: 30px;
                }
                p {
                    font-family: Whyte_Light !important;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 1.4;
                    color: var(--light-gray);
                }
            }
        }
    }
    @media (max-width:767px) {
        .section_3 {
            h2 {
                font-size: 40px;
            }
            .section_content {
                width: 100%;
                grid-template-columns: repeat(1 , 1fr);
                grid-gap: 60px;
                .text_icon {
                    img {
                        width: 80%;
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .section_4 {
        margin-top: var(--sections-spacing);
        padding-right: 10%;
        display: flex;
        flex-direction: column;
        gap: 50px;
        .cols {
            display: grid;
            grid-template-columns: repeat(4 , 1fr);
            padding-top: 60px;
            border-top: 6px solid white;
            grid-gap: 30px;
            width: 90%;
            .col {
                display: flex;
                flex-direction: column;
                gap: 10px;
                strong {
                    font-size: 70px;
                }
                p {
                    font-size: 17px;
                    font-family: Whyte_Light ;
                    line-height: 1.1;
                }
            }
        }
        .section_content {
            display: flex;
            gap: 5%;
            .text {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 50%; 
                align-self: flex-end;
                h2 {
                    color: white;
                    font-size: 40px;
                }
                p {
                    color: var(--light-gray);
                }
            }
            img {
                width: 45%;
                height: 300px;
            }
        }
    }
    @media (max-width:767px) {
        .section_4 {
            padding-right: 0;
            .cols {
                grid-template-columns: 1fr;
                .col {
                    strong {
                        font-size: 50px;
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
            .section_content {
                flex-direction: column;
                gap: 30px;
                .text {
                    width: 100%; 
                    align-self: unset;
                    h2 {
                        font-size: 30px;
                    }
                }
                img {
                    width: 100%;
                }
            }
        }
    }


    .section_5 {
        padding-right: 10%;
        display: flex;
        flex-direction: column;
        gap: 80px;
        .section_text {
            h2 {
                color: white;
                font-size: 55px;
                width: 70%;
                font-family: Whyte_Bold;
            }
            p {
                width: 80%;
                color: var(--light-gray);
                font-family: Whyte_Extra_Light;
                font-size: 18px;
                line-height: 27px;
                padding-left:15%;
                margin-top: 30px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 14%;
                    height: 5px;
                    background: white;
                }
            }
        }
        .team_content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
            .teammate {
                display: flex;
                flex-direction: column;
                gap: 20px;
                img.teammate_img {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    margin-bottom: 10px;
                }
                .teamate_name {
                    font-size:35px;
                }
                small {
                    color: var(--light-gray);
                    font-size: 16px;
                }
                .fiverr_link {
                    background: #dc8b00 !important;
                    padding: 5px 10px;
                    border-radius: 5px;
                    width: 60%;
                    gap: 10px;
                    display: flex;
                    transition: .3s;
                    align-items: center;
                    img {
                        border-radius: 50%;
                        object-fit: cover;
                        height: 20px;
                        width: 20px;
                        transition: .3s;
                    }
                    &:hover {
                        img {
                            transform: rotate(360deg);
                        }
                        gap:15px;
                    }
                }
                .temmate_description {
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 1.3;
                    font-family: Whyte_Extra_Light;
                    color: var(--light-gray);
                    button {
                        background:none;
                        font-family: Whyte_Bold;
                        margin-left: 10px;
                        border: 0;
                        font-size: 18px;
                        cursor: pointer;
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            top: calc(100% + 5px);
                            height: 2px;
                            background: white;
                            left: 0;
                            transform: scaleX(0);
                            transition: .5s;
                            transform-origin: left;
                        }
                        @keyframes fade_in {
                            from {
                                opacity:0;
                            } to {
                                opacity:1;
                            }
                        }
                        &:hover {
                            &::after {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
                h4 {
                    font-size: 20px;
                    font-weight: 200;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    li {
                        padding-left: 20px;
                        position: relative;
                        line-height: 1.2;
                        &::before {
                            content: '';
                            position: absolute;
                            width:  4px;
                            height: 4px;
                            background: white;
                            left: 0;
                            top: 5px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:767px) {
        .section_5 {
            padding-right: 0;
            .section_text {
                h2 {
                    font-size: 40px;
                    width: 100%;
                }
                p {
                    font-size: 16px;
                    width: 100%;
                }
            }
            .team_content {
                grid-template-columns: 1fr;
                .teammate {
                    .teamate_name {
                        font-size:30px;
                    }
                    .temmate_description {
                        font-size: 16px;
                    }
                    h4 {
                        font-size: 20px;
                        font-weight: 200;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        li {
                            padding-left: 20px;
                            position: relative;
                            line-height: 1.2;
                            &::before {
                                content: '';
                                position: absolute;
                                width:  4px;
                                height: 4px;
                                background: white;
                                left: 0;
                                top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    // Edit Padding Right for ABOUT PAGE
    @media (max-width:767px) {
        padding-right: 20px;
    }
}