.contactPage {
    @include toCenter();
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    h1 {
        a {
            color: white;
            font-size: 60px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: calc(100% + 5px); 
                width: 100%;
                height: 4px;
                background: var(--light-gray);
                transition: .3s;
                transform-origin: left;
                transform: scaleX(0); 
            }
            &:hover {
                &::before {
                    transform: scaleX(1);
                }
            }
        }
    }
    a.back {
        position: absolute;
        left: 40px;
        top: 40px;
        color: white;
        display: flex;
        align-items: center;
        gap: 10px;
        i { transition: .3s; }
        &:hover {
            i {
                padding-right: 8px;
            }
        }
    }
}
@media (max-width: 768px) {
    .contactPage {
        h1 {
            font-size: 25px;
        }
    }
}